export default function Footer() {
    return (
        <div className="px-20 py-4 flex justify-between text-sm font-bold items-center">
            <div className="block text-xs leading-12 text-secondary">
                <div className="leading-normal">Theme Designed By</div>
                <div className="leading-normal">Brittany Chiang</div>
                <div className="leading-normal">Developed By</div>
                <div className="leading-normal">Wahib Farhat &copy; 2022</div>
            </div>
            <div className="block">
                <a className="text-accent px-4 underline">
                    EMAIL
                </a>
                <a className="text-accent px-4 underline">
                    LINKEDIN
                </a>
                <a className="text-accent px-4 underline">
                    GITHUB
                </a>
            </div>
        </div>
    )
}