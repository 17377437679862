import AboutSection from './AboutSection'

export default function About() {

    return (
        <div>
            <h2 className="
                    text-center
                    text-2xl
                    font-bold
                    text-white 
                    p-32">
                About me
            </h2>
            <AboutSection
                title={'Background'}
                description={
                    <div>
                        I have a BSc in Computer Engineering and I'm currently working at <b>Vodafone Ghana</b>,
                        where I've been integrating Business Support Systems for the past 4 years.
                        As a software engineer, I turn technical problems into growth opportunities and I'm comfortable working across the web stack.
                        When I'm not coding, you can find me drawing on my iPad, swimming or watching TV shows.
                    </div>} />

            <AboutSection
                title={'Tech Stack'}
                description={
                    <ul>
                        <li>Python</li>
                        <li>Django, Django Rest Framework, FastAPI</li>
                        <li>PostgreSQL, MongoDB, Redis</li>
                        <li>Celery</li>
                        <li>Typescript</li>
                        <li>ReactJS</li>
                        <li>NextJS</li>
                        <li>tailwindcss</li>
                    </ul>} />

            <AboutSection
                title={'Tools'}
                description={
                    <ul>
                        <li>Bash</li>
                        <li>Vim</li>
                        <li>Git</li>
                        <li>Wireshark</li>
                        <li>Docker</li>
                        <li>Postman</li>
                    </ul>} />
        </div>
    )
}