export default function Intro() {

    return (
        <div>
            <h1 className="text-center text-4xl font-bold text-white p-32">
                Wahib Farhat
            </h1>
            <div className='flex items-center justify-center'>
                <h2 className='text-center text-4xl text-white max-w-lg leading-normal mb-20'>
                    An experienced back-end developer focused on designing and building resilient systems
                </h2>
            </div>
            <p className='text-center text-white text-xl'>
                Get in touch:
                <a className="text-accent underline font-bold ml-2" href="mailto:wahibfarhat13@gmail.com">
                    wahibfarhat13@gmail.com
                </a>
            </p>
        </div>
    )

}
