export default function AboutSection(
    {
        title: title,
        description: description
    }
) {

    return (
        <div className="mb-8">
            <section className="flex justify-center">
                <div className="text-accent w-48 text-2xl font-bold">
                    {title}
                </div>
                <div className="w-1/3 text-secondary ">
                    {description}
                </div>
            </section>
        </div>
    )

}