import './App.css';
import About from './About';
import Intro from './Intro';
import FeaturedProjects from './FeaturedProjects';
import Footer from './Footer';

function App() {
  return (
    <div className="bg-primary h-fit">
      <Intro />
      <About />
      <p className='text-center text-white text-xl p-28'>
        <a className="text-accent underline font-bold" href="./CV_Wahib_Farhat.pdf">
          View My Resume
        </a>
      </p>
      {/* <FeaturedProjects /> */}
      <Footer />
    </div>
  );
}

export default App;
